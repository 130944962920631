<template>
  <div class="exercise-container">
    <h1>{{ exercise.name }}</h1>
    <img
      v-if="exercise.image"
      class="exercise-image"
      :src="exercise.image"
      alt="Exercise image"
    />
    <p v-if="exercise.description">
      {{ exercise.description }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Exercise",
  computed: {
    exercise () {
      return this.$store.getters.exercise(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.exercise-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.exercise-image {
  width: 100%;
}
</style>
