import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Contacts from "../views/old/Contacts"
import NotFound from "../views/NotFound"
import WarmUp from "../views/exerciseCategories/WarmUp"
import WarmUpGoalie from "../views/exerciseCategories/WarmUpGoalie"
import GamesWithBall from "../views/exerciseCategories/GamesWithBall"
import Shooting from "../views/exerciseCategories/Shooting"
import Passing from "../views/exerciseCategories/Passing"
import Defending from "../views/exerciseCategories/Defending"
import Exercise from "../views/Exercise"
import CreateTraining from "../views/CreateTraining"
import Training from "../views/Training"
import CreatedTraining from "../views/CreatedTraining"
import Login from "../views/Login"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/member",
    name: "Member",
    beforeEnter () { location.href = "http://clen.1fbktabor.cz/" },
    component: NotFound
  },
  {
    path: "/rozbehani",
    name: "WarmUp",
    component: WarmUp
  },
  {
    path: "/rozchytani",
    name: "WarmUpGoalie",
    component: WarmUpGoalie
  },
  {
    path: "/cviceni-s-mickem",
    name: "GamesWithBall",
    component: GamesWithBall
  },
  {
    path: "/strelba",
    name: "Shooting",
    component: Shooting
  },
  {
    path: "/nahravky",
    name: "Passing",
    component: Passing
  },
  {
    path: "/obrana",
    name: "Defending",
    component: Defending
  },
  {
    path: "/cviceni/:id",
    name: "Exercise",
    component: Exercise
  },
  {
    path: "/vytvorit-trenink",
    name: "CreateTraining",
    component: CreateTraining
  },
  {
    path: "/trenink/:id",
    name: "Training",
    component: Training
  },
  {
    path: "/vytvoreny-trenink/:id",
    name: "CreatedTraining",
    component: CreatedTraining
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/404",
    name: "NotFound404",
    component: NotFound
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
