<template>
  <section class="categories">
    <div class="categories-title">
      <h1>Kategorie cvičení</h1>
    </div>
    <div>
      <div class="categories-row">
        <div
          v-for="category in categories"
          :key="category.id"
          class="category"
        >
          <h2 class="category-name">
            {{ category.name }}
          </h2>
          <img
            class="exercise-image-preview"
            :src="category.image"
            alt="Floorball field"
          >
          <router-link
            class="button"
            :to="{ name: category.link }"
          >
            Přejít na seznam
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Home",
  data () {
    return {
      categories: [
        { name: "Rozběhání", link: "WarmUp", image: require("../assets/exercises/florbal-hriste.svg") },
        { name: "Rozchytání", link: "WarmUpGoalie", image: require("../assets/exercises/rohy.svg") },
        { name: "Cvičení s míčkem", link: "GamesWithBall", image: require("../assets/exercises/florbal-hriste.svg") },
        { name: "Střelba", link: "Shooting", image: require("../assets/exercises/florbal-hriste.svg") },
        { name: "Nahrávky", link: "Passing", image: require("../assets/exercises/florbal-hriste.svg") },
        { name: "Obrana", link: "Defending", image: require("../assets/exercises/florbal-hriste.svg") }
      ]
    }
  },
  mounted () {
    document.body.style.background = "#1E44ACFF"
  },
  beforeUnmount () {
    document.body.style.background = "initial"
  }
}
</script>

<style lang="scss" scoped>
.club-welcome {
  width: 100%;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../assets/buly-scaled.jpg') no-repeat center center fixed;
  background-size: cover;
  color: white;

  .club-name {
    font-size: 120px;
    font-weight: 600;
    max-width: 90%;

    @media (max-width: 1145px) {
      font-size: 80px;
    }
    @media (max-width: 445px) {
      font-size: 50px;
    }
  }

  .club-trainings {
    font-size: 80px;
    font-weight: 500;
    max-width: 90%;

    @media (max-width: 1145px) {
      font-size: 25px;
    }
    @media (max-width: 445px) {
      font-size: 20px;
    }
  }
}

.categories {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  background: var(--dark-blue);

  .categories-title {
    h1 {
      color: white;
      font-size: 50px;

      @media (max-width: 800px) {
        font-size: 35px;
      }
    }
  }

  @media (max-width: 945px) {
    height: auto;
  }

  .categories-row {
    width: 260*3px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    @media (max-width: 850px) {
      width: 260*2px;
    }
    @media (max-width: 650px) {
      width: 260px;
    }
  }
}

.category {
  width: 200px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--dark-blue);
  margin: 20px;
  padding: 0 10px;

  h2 {
    font-size: 28px;
  }

  .category-contact {
    line-height: 20px;
  }

  .button {
    margin-top: 23px;
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
