export default {
  data () {
    return {
      tagsTranslation: {
        WarmUpGoalie: { name: "Rozchytání" },
        WarmUp: { name: "Rozběhání" },
        Shooting: { name: "Střelba" },
        GamesWithBall: { name: "Cvičení s míčkem" },
        Defending: { name: "Obrana" },
        Passing: { name: "Nahrávky" }
      }
    }
  },
  methods: {
    translateTag (id) {
      if (this.tagsTranslation[id]) {
        return this.tagsTranslation[id].name
      }
      return "Neznámá kategorie"
    }
  }
}
