<template>
  <div class="create-training-wrapper">
    <h1>Trénink</h1>
    <div
      v-for="(exercise, index) in exercises"
      :key="index"
      class="exercise-wrapper"
    >
      <h2>{{ exercise.selected.name }}</h2>
      <div class="actions-wrapper">
        <details
          v-if="exercise.selected.image"
          class="exercise-image-slot"
        >
          <summary class="exercise-image-title">
            Náhled cvičení
          </summary>
          <img
            :src="exercise.selected.image"
            alt=""
            class="exercise-image"
          />
        </details>
      </div>
    </div>
  </div>
</template>

<script>
import db from "../plugins/firebase"
export default {
  name: "Training",
  data () {
    return {
      exercises: []
    }
  },
  computed: {
    training () {
      return this.$route.params.id
    }
  },
  created () {
    db.collection("trainings").doc(this.training).get()
      .then((doc) => {
        if (doc.exists) {
          this.exercises = doc.data().exercises
        } else {
        // doc.data() will be undefined in this case
          console.log("No such document!")
          this.$router.push({ name: "NotFound" })
        }
      }).catch((error) => {
        console.log("Error getting document:", error)
        this.$router.push({ name: "NotFound" })
      })
  },
  methods: {
    addExercise () {
      this.selectedExercises.push({ selected: null })
    },
    deleteExercise (index) {
      this.selectedExercises.splice(index, 1)
    }
  }
}
</script>

<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>

<style lang="scss" scoped>
.button-delete-icon {
  background: #cc0000;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: red;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.button-delete {
  margin-bottom: 15px;
  height: 15px;
  @media (min-width: 801px) {
    display: none;
  }
}

.actions-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 666px) {
    flex-direction: column;
  }
}

.button-save {
  margin-top: 10px;
}

.exercise-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.exercise-inner-wrappper {
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.exercise-duration {
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  padding-left: 10px;
  border: 1px solid #d2d6de;
  padding-top: 5px;
  color: var(--primary-color);

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 10px;
    height: 32px;
  }

  @media (min-width: 801px) {
    margin-right: 15px;
  }

  &:focus {
    outline: none;
  }
}

input::-webkit-input-placeholder {
  color: var(--primary-color);
}

.training-duration {
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  opacity: 1;
  padding-left: 10px;
  border: 1px solid #d2d6de;
  padding-top: 5px;
  height: 32px;
  margin-bottom: 15px;

  &:focus {
    outline: none;
  }
}

.exercise-image-slot {
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    margin-right: 15px;
  }
}

.exercise-image-title {
  cursor: pointer;
  background: var(--dark-blue);
  padding-top: 15px;
  height: 30px;
  color: white;
  font-family: Quicksand, sans-serif;
  font-weight: 500;
}

.exercise-image {
  margin-top: 15px;
  width: 100%;
}

.exercise-select {
  margin-right: 15px;
}

.create-training-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    width: 80%;
  }
}

::v-deep(.multiselect__tag) {
  border-radius: 3px;
  background-color: #3c8dbc;
}

::v-deep(.multiselect__tag-icon:after) {
  color: #235470;
}

::v-deep(.multiselect__tags) {
  border-radius: 0;
  border-color: #d2d6de;
  padding-top: 11px;
}

::v-deep(.multiselect__placeholder) {
  color: var(--primary-color);
}

::v-deep(.multiselect__option--highlight) {
  background-color: var(--dark-blue);
}

::v-deep(.multiselect__option--group) {
  background: #d9d9d9 !important;
  color: #606060 !important;
}

::v-deep(.multiselect__spinner:before) {
  border-top-color: var(--dark-blue);
}

::v-deep(.multiselect__spinner:after) {
  border-top-color: var(--dark-blue);
}
</style>
