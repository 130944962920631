<template>
  <div
    v-if="show"
    class="dialogPosition"
  >
    <h2>
      Je k dispozici nová verze!
    </h2>
    <div class="float-right mb-2 mr-2">
      <input
        type="button"
        class="button quicksand"
        value="Obnovit"
        @click="refresh"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NewContentDialog",
  computed: {
    show () {
      return this.$store.getters.newContent
    }
  },
  methods: {
    refresh () {
      this.$store.commit("newContent", false)
      location.reload(true)
    }
  }
}
</script>

<style scoped lang="scss">
.dialogPosition {
  position: fixed;
  right: 1em;
  bottom: 1em;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 10px;
}
</style>
