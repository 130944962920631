<template>
  <div class="not-found">
    <h1>Nenalezeno</h1>
    <router-link
      class="button"
      :to="{name: 'Home'}"
    >
      Zpět na hlavní stránku
    </router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5rem);

  @media (max-height: 300px) {
    justify-content: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
