<template>
  <div class="created-training-wrapper">
    <h1>Trénink byl vytvořen</h1>
    <h3>
      Trénink si můžete zobrazit na následující stránce:
      <router-link
        :to="{ name: 'Training', params: { id: trainingId }}"
      >
        odkaz
      </router-link>
    </h3>
    <h3>Tento odkaz si uložte pro budoucí přístup</h3>
    <div
      class="button"
      @click="copy"
    >
      Zkopírovat do schránky
    </div>
    <p
      :class="{'hidden': !copied}"
      style="color: green"
    >
      Zkopírováno
    </p>
    <p
      :class="{'hidden': !copyError}"
      style="color: red"
    >
      Nastala chyba, zkopírujte link ručně
    </p>
  </div>
</template>
<script>
export default {
  name: "CreatedTraining",
  data () {
    return {
      copied: false,
      copyError: false
    }
  },
  computed: {
    trainingId () {
      return this.$route.params.id
    }
  },
  methods: {
    copy () {
      const input = window.location.origin + "/trenink/" + this.trainingId
      if (navigator.clipboard) {
        navigator.clipboard.writeText(input).then(() => {
          this.copied = true
          this.copyError = false
        }, () => {
          this.copied = false
          this.copyError = true
        })
      } else if (window.clipboardData) {
        window.clipboardData.setData("Text", input)
        this.copied = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  a {
    color: var(--dark-blue);
  }
}

.created-training-wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
}

.button {
  margin-top: 15px;
  width: 300px;
  max-width: 80%;
}
</style>
