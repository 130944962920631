<template>
  <div class="wrapper">
    <h3>Uživatelské jméno</h3>
    <input
      class="input-box quicksand"
      type="text"
    >
    <h3>Heslo</h3>
    <input
      class="input-box quicksand"
      type="password"
    >
    <input
      type="button"
      class="button quicksand sign-in"
      value="Přihlásit se"
    >
  </div>
</template>

<script>
export default {
  name: "Login"
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 5rem);
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.sign-in {
  margin-top: 20px;
}

.input-box {
  border-radius: 0;
  border: black solid 1px;
  outline: none;
  padding: 20px 5px 5px 5px;
}
</style>
