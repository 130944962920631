<template>
  <section class="training-categories">
    <h1 class="training-categories-title">
      Obrana
    </h1>
    <div class="training-categories-row">
      <div
        v-for="exercise in exercises"
        :key="exercise.id"
        class="training-category"
      >
        <h2>{{ exercise.name }}</h2>
        <img
          class="exercise-image-preview"
          :src="exercise.image"
          alt="Exercise image"
        >
        <router-link
          class="button"
          :to="{ name: 'Exercise', params: { id: exercise.id }}"
        >
          Přejít na cvičení
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Defending",
  computed: {
    exercises () {
      return this.$store.getters.specificExercises("Defending")
    }
  }
}
</script>

<style scoped>

</style>
