import { createStore } from "vuex"

export default createStore({
  state: {
    exercises: {
      pulkruh: { name: "Půlkruh", id: "pulkruh", tags: ["WarmUpGoalie"], image: require("../assets/exercises/pulkruh.svg") },
      rohy: { name: "Rohy", id: "rohy", tags: ["WarmUpGoalie"], image: require("../assets/exercises/rohy.svg") },
      hadi: { name: "Hadi", id: "hadi", tags: ["WarmUpGoalie"], image: require("../assets/exercises/florbal-hriste.svg") },
      fini: { name: "Fini", id: "fini", tags: ["WarmUpGoalie"], image: require("../assets/exercises/florbal-hriste.svg") },
      obalka: { name: "Obálka", id: "obalka", tags: ["WarmUpGoalie"], image: require("../assets/exercises/florbal-hriste.svg") },
      liftink: { name: "Liftink", id: "liftink", tags: ["WarmUp"], image: require("../assets/exercises/florbal-hriste.svg") },
      skipink: {
        name: "Skipink",
        id: "skipink",
        tags: ["WarmUp"],
        image: require("../assets/exercises/florbal-hriste.svg")
      },
      zakopavani: { name: "Zakopávání ", id: "zakopavani", tags: ["WarmUp"], image: require("../assets/exercises/florbal-hriste.svg") },
      predkopavani: { name: "Předkopávání", id: "predkopavani", tags: ["WarmUp"], image: require("../assets/exercises/florbal-hriste.svg") },
      "poskoky-stranou": { name: "Poskoky stranou", id: "poskoky-stranou", tags: ["WarmUp"], image: require("../assets/exercises/florbal-hriste.svg") },
      vanocka: { name: "Vánočka", id: "vanocka", tags: ["WarmUp"], image: require("../assets/exercises/florbal-hriste.svg") },
      "2na1": { name: "2 na 1", id: "2na1", tags: ["WarmUpGoalie"], image: require("../assets/exercises/2na1.svg") },
      "opakovana-strelba-z-prvni": { name: "Opakovaná střelba z první", id: "opakovana-strelba-z-prvni", tags: ["Shooting"], image: require("../assets/exercises/opakovana-strelba-z-prvni.jpg") },
      "dve-strely-po-dvojite-vymene-micku": { name: "Dvě střely po dvojité výměně míčku", id: "dve-strely-po-dvojite-vymene-micku", tags: ["Shooting"], image: require("../assets/exercises/dve-strely-po-dvojite-vymene-micku.jpg") },
      "2na1-na-pulce": { name: "2 na 1 na půlce", id: "2na1-na-pulce", tags: ["Defending", "Shooting"], image: require("../assets/exercises/2na1-na-pulce.jpg") }
    },
    newContent: false
  },
  mutations: {
    newContent: (state, value) => {
      state.newContent = value
    }
  },
  getters: {
    exercises (state) {
      return state.exercises
    },
    exercise (state) {
      return function (id) {
        return state.exercises[id]
      }
    },
    specificExercises (state) {
      return function (category) {
        const result = {}
        for (const [key, value] of Object.entries(state.exercises)) {
          if (value.tags.includes(category)) {
            result[key] = value
          }
        }
        return result
      }
    },
    newContent: state => state.newContent
  },
  actions: {
  },
  modules: {
  }
})
