<template>
  <!-- TODO: Responsive view -->
  <div class="create-training-wrapper">
    <h1>Vytvořit trénink</h1>
    <input
      style="display: none"
      class="training-duration"
      type="number"
      placeholder="Celková doba tréninku"
    >
    <div
      v-for="(exercise, index) in selectedExercises"
      :key="index"
      class="exercise-wrapper"
    >
      <div class="exercise-inner-wrappper">
        <Multiselect
          v-model="exercise.selected"
          :class="{ 'error-border' : exercise.error }"
          track-by="id"
          label="name"
          :show-labels="false"
          :close-on-select="true"
          :options="exercises"
          group-label="tagName"
          group-values="exercises"
          :group-select="false"
          placeholder="Vyberte cvičení"
          class="exercise-select"
          @select="deleteError(exercise)"
          @remove="addError(exercise)"
        />
        <input
          style="display: none"
          class="exercise-duration"
          type="number"
          placeholder="Doba cvičení"
        >
        <mdicon
          class="button-delete-icon"
          name="close"
          size="32"
          @click="deleteExercise(index)"
        />
      </div>
      <span
        class="error-message"
        :class="{ 'hidden' : !exercise.error }"
        style="color: red !important;"
      >
        <i class="glyphicon glyphicon-exclamation-sign" /> Musíte vybrat nějaké cvičení!
      </span>
      <div class="actions-wrapper">
        <details
          v-if="exercise.selected && exercise.selected.image"
          class="exercise-image-slot"
        >
          <summary class="exercise-image-title">
            Náhled cvičení
          </summary>
          <img
            :src="exercise.selected.image"
            alt=""
            class="exercise-image"
          >
        </details>
        <div
          class="button-delete"
          @click="deleteExercise(index)"
        >
          Odstranit cvičení
        </div>
      </div>
    </div>
    <div
      class="button"
      @click="addExercise"
    >
      Přidat cvičení
    </div>
    <div
      class="button-save"
      @click="saveExercise"
    >
      Uložit trénink
    </div>
  </div>
</template>

<script>
import Multiselect from "@suadelabs/vue3-multiselect"
import translateTag from "../mixins/translateTag"
import db from "../plugins/firebase"
export default {
  name: "CreateTraining",
  components: {
    Multiselect
  },
  mixins: [translateTag],
  data () {
    return {
      exercises: [],
      tags: [],
      selectedExercises: []
    }
  },
  computed: {
    exercisesObj () {
      return this.$store.getters.exercises
    }
  },
  created () {
    // for (const [key, value] of Object.entries(this.exercisesObj)) {
    //   value.tags.forEach(tag => {
    //     if (!this.exercises.some(exercise => exercise.tag === tag)) {
    //       // If there isn't exercise group with that tag, add it.
    //       this.exercises.push({ tag: tag, tagName: this.translateTag(tag), exercises: [] })
    //     } else if (!this.exercises.some(exercise => exercise.tag === tag && exercise.id === value.id)) {
    //       // If there is exercise group with that tag, find it and add this exercise, if it doesn't exist already.
    //       this.exercises.find(exercise => exercise.tag === tag)
    //         .exercises
    //         .push({ id: key, name: value.name, tags: value.tags, image: value.image })
    //     }
    //   })
    // }
    // TODO: Find better way to format data
    // eslint-disable-next-line no-unused-vars
    for (const [_, value] of Object.entries(this.exercisesObj)) {
      value.tags.forEach(tag => {
        if (!this.tags.includes(tag)) {
          this.tags.push(tag)
        }
      })
    }
    this.tags.forEach(tag => {
      this.exercises.push({ tag: tag, tagName: this.translateTag(tag), exercises: [] })
    })
    for (const [key, value] of Object.entries(this.exercisesObj)) {
      this.exercises.find(tagGroup => value.tags.includes(tagGroup.tag))
        .exercises
        .push({ id: key, name: value.name, tags: value.tags, image: value.image, error: false })
    }
    console.info(this.exercises)
  },
  methods: {
    addExercise () {
      this.selectedExercises.push({ selected: null })
    },
    deleteExercise (index) {
      this.selectedExercises.splice(index, 1)
    },
    saveExercise () {
      this.selectedExercises.forEach(exercise => {
        exercise.error = exercise.selected === "" || exercise.selected === null
      })
      if (this.selectedExercises.some(exercise => exercise.error)) return
      db.collection("trainings").add({
        exercises: this.selectedExercises
      })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id)
          this.$router.push({
            name: "CreatedTraining",
            params: {
              id: docRef.id
            }
          })
        })
    },
    deleteError (exercise) {
      exercise.error = false
    },
    addError (exercise) {
      exercise.error = true
    }
  }
}
</script>

<style src="@suadelabs/vue3-multiselect/dist/vue3-multiselect.css"></style>

<style lang="scss" scoped>
.button-delete-icon {
  background: #cc0000;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: red;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.button-delete {
  margin-bottom: 15px;
  height: 15px;
  @media (min-width: 801px) {
    display: none;
  }
}

.actions-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 666px) {
    flex-direction: column;
  }
}

.button-save {
  margin-top: 10px;
}

.exercise-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.exercise-inner-wrappper {
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.exercise-duration {
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  padding-left: 10px;
  border: 1px solid #d2d6de;
  padding-top: 5px;
  color: var(--primary-color);

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 10px;
    height: 32px;
  }

  @media (min-width: 801px) {
    margin-right: 15px;
  }

  &:focus {
    outline: none;
  }
}

input::-webkit-input-placeholder {
  color: var(--primary-color);
}

.training-duration {
  font-family: Quicksand, sans-serif;
  font-size: 15px;
  opacity: 1;
  padding-left: 10px;
  border: 1px solid #d2d6de;
  padding-top: 5px;
  height: 32px;
  margin-bottom: 15px;

  &:focus {
    outline: none;
  }
}

.exercise-image-slot {
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    margin-right: 15px;
  }
}

.exercise-image-title {
  cursor: pointer;
  background: var(--dark-blue);
  padding-top: 15px;
  height: 30px;
  color: white;
  font-family: Quicksand, sans-serif;
  font-weight: 500;
}

.exercise-image {
  margin-top: 15px;
  width: 100%;
}

.exercise-select {
  margin-right: 15px;
}

.create-training-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    width: 80%;
  }
}

::v-deep(.multiselect__tag) {
  border-radius: 3px;
  background-color: #3c8dbc;
}

::v-deep(.multiselect__tag-icon:after) {
  color: #235470;
}

::v-deep(.multiselect__tags) {
  border-radius: 0;
  border-color: #d2d6de;
  padding-top: 11px;
}

::v-deep(.multiselect__placeholder) {
  color: var(--primary-color);
}

::v-deep(.multiselect__option--highlight) {
  background-color: var(--dark-blue);
}

::v-deep(.multiselect__option--group) {
  background: #d9d9d9 !important;
  color: #606060 !important;
}

::v-deep(.multiselect__spinner:before) {
  border-top-color: var(--dark-blue);
}

::v-deep(.multiselect__spinner:after) {
  border-top-color: var(--dark-blue);
}

.error-border {
  ::v-deep(.multiselect__tags) {
    border-color: red !important;
  }
}

.error-message {
  margin-bottom: 10px;
}
</style>
