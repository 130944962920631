<template>
  <header
    class="app-top-bar"
    :class="{ 'is-hidden': !showHeader }"
  >
    <router-link
      :to="{ name: 'Home' }"
      class="logo"
      @click="showSidebar = false"
    >
      1.FbK Tábor
    </router-link>
    <div class="motto">
      Pro ty co florbal baví!
    </div>
    <div class="menu">
      <mdicon
        class="menu-button"
        name="menu"
        size="50"
        @click="showSidebar = !showSidebar"
      />
    </div>
  </header>
  <AppSideBar
    v-show="showSidebar && showHeader"
    @sideBarOff="showSidebar = false"
  />
</template>

<script>
import AppSideBar from "./AppSideBar"
export default {
  name: "AppTopBar",
  components: { AppSideBar },
  data () {
    return {
      showHeader: true,
      lastScrollPosition: 0,
      scrollOffset: 16 * 5,
      showSidebar: false
    }
  },
  watch: {
    showHeader () {
      if (!this.showHeader) {
        this.showSidebar = false
      }
    }
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener("scroll", this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    // Toggle if navigation is shown or hidden
    onScroll () {
      if (window.pageYOffset < 16 * 7) {
        this.showHeader = true
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.showHeader = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  font-size: 35px;
  margin-left: 150px;
  color: white;
  font-family: "quicksand", sans-serif;
  font-weight: 600;
  padding-top: 6px;
  text-decoration: none;

  &:hover {
    color: var(--secondary-color);
  }

  @media (max-width: 885px) {
    margin-left: 50px;
  }
  @media (max-width: 445px) {
    font-size: 25px;
    margin-left: 25px;
  }
}

.motto {
  font-size: 25px;
  color: white;
  font-family: "quicksand", sans-serif;
  font-weight: 600;
  padding-top: 6px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);

  @media (max-width: 1145px) {
    display: none;
  }
}

.app-top-bar {
  z-index: 10;
  width: 100%;
  height: 5rem;
  background: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 150ms linear;

  .menu {
    z-index: 11;
    margin-right: 150px;
    cursor: pointer;

    .menu-button {
      color: white;

      &:hover {
        color: var(--secondary-color);
      }
    }

    @media (max-width: 885px) {
      margin-right: 50px;
    }

    @media (max-width: 445px) {
      margin-right: 25px;
    }
  }
}

.is-hidden {
  transform: translateY(-100%);
}
</style>
