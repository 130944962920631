<template>
  <div class="sidebar">
    <router-link
      v-for="(link, index) in links"
      :key="index"
      :to="{name: link.name}"
      @click="sideBarOff"
    >
      {{ link.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AppSideBar",
  emits: ["sideBarOff"],
  data () {
    return {
      links: [
        { name: "WarmUp", text: "Rozběhání" },
        { name: "WarmUpGoalie", text: "Rozchytání" },
        { name: "GamesWithBall", text: "Hry s míčkem" },
        { name: "Shooting", text: "Střelba" },
        { name: "Passing", text: "Nahrávky" },
        { name: "Defending", text: "Obrana" },
        { name: "CreateTraining", text: "Vytvořit trénink" },
        { name: "Login", text: "Přihlásit se" }
      ]
    }
  },
  methods: {
    sideBarOff () {
      this.$emit("sideBarOff")
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: var(--primary-color);
  position: fixed;
  width: 100%;
  top: 5rem;
  left: 0;
  bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  overflow: auto;
  overscroll-behavior: contain;

  font-size: 35px;

  @media (max-width: 800px) {
    font-size: 25px;
  }

  a {
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 5px;

    &:hover {
      color: var(--secondary-color);
    }
  }

  a.router-link-exact-active {
    color: var(--secondary-color);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: none;
  border-radius: 10px;
}
</style>
