<template>
  <transition name="fade">
    <div
      v-if="loading"
      class="container"
    >
      <div class="overlay">
        <div class="overlay-bg"></div>
        <transition name="bounce">
          <div
            v-if="loadingLogo"
            class="content"
          >
            <h1>Pro ty co florbal baví!</h1>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loading",
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    loadingLogo: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity .5s;
}

.fade-leave-active {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.overlay-bg {
  background: var(--dark-blue);
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.content {
  position: relative;
}

h1 {
  font-size: calc(5vw + 30px);
  color: white;
  background-size: 250%;
  animation: animate 3s ease-in-out infinite;
}

@keyframes animate {
  0%, 100% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 0 0;
  }
}
</style>
