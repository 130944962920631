const firebaseConfig = {
  apiKey: "AIzaSyBVqIM9qGI77epXIYpQlRsdx7GQ--vbr88",
  authDomain: "fbk-tabor.firebaseapp.com",
  projectId: "fbk-tabor",
  storageBucket: "fbk-tabor.appspot.com",
  messagingSenderId: "263572946032",
  appId: "1:263572946032:web:c2f1c1c8386d84d23408b5"
}

export default firebaseConfig
