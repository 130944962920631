<template>
  <AppTopBar />
  <NewContentDialog />
  <div class="main-wrapper">
    <Loading
      :loading="false"
      :loading-logo="false"
    />
    <router-view v-if="true" />
  </div>
</template>

<script>
import "./style/common.scss"
import AppTopBar from "./components/AppTopBar"
import Loading from "./components/Loading"
import NewContentDialog from "./components/NewContentDialog"
export default {
  components: { NewContentDialog, Loading, AppTopBar },
  data: () => ({
    loading: false,
    loadingLogo: false,
    firstTimeLoad: true
  }),
  computed: {
    routeName () {
      return this.$route.name
    }
  },
  watch: {
    routeName: {
      immediate: true,
      handler () {
        if (this.routeName !== undefined && this.firstTimeLoad) {
          this.firstTimeLoad = false
          this.loading = this.routeName === "Home"
        }
      }
    }
  },
  created () {
    this.loadingLogo = true
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 3000)
    setTimeout(() => {
      this.loadingLogo = false
    }, 2500)
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.top-bar-undercover {
  height: 5rem;
  width: 100%;
  background: var(--dark-blue);
}
.main-wrapper {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
