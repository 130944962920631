<template>
  <div class="contacts">
    <h1>Kontakty</h1>
    <table>
      <thead>
        <tr>
          <td>Tým</td>
          <td>Vedoucí družstva</td>
          <td>Hlavní trenér</td>
          <td
            class="has-text-align-center"
            data-align="center"
          >
            Asistenti
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(contact, index) in contacts"
          :key="index"
        >
          <td>{{ contact.team }}</td>
          <td v-if="contact.managerName">
            {{ contact.managerName }} <br>{{ contact.managerPhoneNum }}
          </td>
          <td v-else>
            -
          </td>
          <td v-if="contact.headCoachName">
            {{ contact.headCoachName }} <br>{{ contact.headCoachPhoneNum }}
          </td>
          <td v-else>
            -
          </td>
          <td v-if="contact.assistantName">
            {{ contact.assistantName }} <br>{{ contact.assistantPhoneNum }}
          </td>
          <td v-else>
            -
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data () {
    return {
      contacts: [
        { team: "Muži", managerName: "Kutner Petr", managerPhoneNum: "734 878 249" },
        { team: "Ženy a juniorky", managerName: "Kolínová Anna", managerPhoneNum: "720 510 208", assistantName: "Zenkl Dominik", assistantPhoneNum: "777 905 316" },
        { team: "Junioři", managerName: "Holub Radek", managerPhoneNum: "775 953 290", headCoachName: "Kalina František", headCoachPhoneNum: "773 262 263" },
        { team: "Dorostenci a starší žáci", managerName: "Chalupa Eduard", managerPhoneNum: "736 631 963", headCoachName: "Kolář Jan" },
        { team: "Mladší žáci", managerName: "Vodrážka David", managerPhoneNum: "775 153 102", headCoachName: "Jiroušková Pavla", headCoachPhoneNum: "774 599 903", assistantName: "Klípa Miroslav", assistantPhoneNum: "775 929 378" },
        { team: "Přípravka a elévové", managerName: "Václavík Dušan", managerPhoneNum: "776 736 501", assistantName: "Lukáš Tichý", assistantPhoneNum: "725 933 502" }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  max-width: 90%;
}

table {
  font-weight: 500;
  border: 3px solid #ececec;
  border-collapse: collapse;

  tr {
    height: 50px;
  }

  tbody tr:nth-child(odd) {
    background-color: #ececec;
  }

  td {
    padding: 5px;
  }
}
</style>
